import { Input } from "@windmill/react-ui";
const InputArea = ({
  register,
  defaultValue,
  required,
  name,
  label,
  type,
  placeholder,
  className,
  dark = true,
  style,
  setSelected = null,
  ...rest
}) => {
  const handleChange = (e) => {
    // Limiton 2 decimal places
    let val;
    if (e.target.type !== "number") {
      val = e.target.value;
    } else if (
      e.target.value.toString().split(".")[1] &&
      e.target.value.toString().split(".")[1].length > 2
    ) {
      val = e.target.value = parseFloat(e.target.value).toFixed(2);
    } else {
      val = e.target.value;
    }
    if (setSelected) {
      setSelected(val);
    }
    return val;
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <Input
        {...(register ? register(`${name}`, {
          onChange: handleChange,
          required: required ? `${label} is required!` : false,
        }) : {
          onChange: handleChange,
          required: required ? `${label} is required!` : false,
        })}      
        onKeyDown={(e) => {
          if (type === "number" && e.key === "e") {
            e.preventDefault();
          }
        }}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        name={name}
        className={`${className} 
           border h-10 text-sm focus:outline-none block w-full ${dark && "dark:bg-white"
          }  border-transparent focus:bg-white `}
        {...rest}
        style={{
          borderRadius: "4px",
          ...style,
        }}
        autoComplete="off"
        onWheel={numberInputOnWheelPreventChange}
      />
    </>
  );
};

export default InputArea;
