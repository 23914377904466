import { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../context/SidebarContext";

const useToggleDrawer = () => {
  const [serviceId, setServiceId] = useState("");
  const [service, setService] = useState();
  const { toggleDrawer, isDrawerOpen, toggleModal, setCreate, setCreateBranch } =
    useContext(SidebarContext);

  const handleUpdate = (id, service ) => {
    setServiceId(id);
    setService(service);
    toggleDrawer();
  };

  const handleModalOpen = (id, service) => {
    setServiceId(id);
    setService(service);
    toggleModal();
  };

  const handleCreateOpen = (id) => {
    setCreate(true);
  };

  const handleCreateBranchOpen = (id) => {
    setCreateBranch(true);
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      setServiceId();
      setService();
    }
  }, [isDrawerOpen]);

  return {
    serviceId,
    service,
    handleModalOpen,
    handleCreateOpen,
    handleCreateBranchOpen,
    handleUpdate,
    setServiceId,
    toggleDrawer,
  };
};

export default useToggleDrawer;
