import React from "react";

const products = [
  {
    id: 1,
    name: "Diphenhydramine HCI 25mg - Allergy Medicine",
    price: 4.99,
    quantity: 1,
    image: "https://via.placeholder.com/80.png?text=Diphenhydramine",
  },
  {
    id: 2,
    name: "Ibuprofen 200mg - Pain Reliever",
    price: 8.45,
    quantity: 2,
    image: "https://via.placeholder.com/80.png?text=Ibuprofen",
  },
  {
    id: 3,
    name: "Metformin 500mg - Diabetes Management",
    price: 12.0,
    quantity: 3,
    image: "https://via.placeholder.com/80.png?text=Metformin",
  },
  {
    id: 4,
    name: "Paracetamol Extra Strength",
    price: 5.5,
    quantity: 1,
    image: "https://via.placeholder.com/80.png?text=Paracetamol",
  },
  {
    id: 5,
    name: "Amoxicillin 250mg - Antibiotic",
    price: 10.0,
    quantity: 2,
    image: "https://via.placeholder.com/80.png?text=Amoxicillin",
  },
];

const RemoteCart = () => {
  const calculateSubtotal = () => {
    return products
      .reduce((acc, product) => acc + product.price * product.quantity, 0)
      .toFixed(2);
  };

  const subtotal = calculateSubtotal();
  const vat = (subtotal * 0.1).toFixed(2);
  const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

  return (
    <div>
      <div className="fixed bottom-0 left-0 w-full bg-blue-900 text-white p-4 py-1 text-center z-50">
        <h4>Remote Cart</h4>
      </div>
      <div className="container mx-auto my-4 pb-24 pt-16">
        <div className="space-y-4">
          {products.map((product) => (
            <div
              className="flex items-center justify-between bg-white p-4 border-b-2 border-gray-200"
              key={product.id}
            >
              <div className="flex items-center">
                <div
                  className="bg-gray-200 w-20 h-20 mr-4"
                  style={{
                    backgroundImage: `url(${product.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div>
                  <h5 className="text-lg font-semibold">{product.name}</h5>
                  <p className="text-gray-600">
                    ${product.price.toFixed(2)} x {product.quantity}
                  </p>
                </div>
              </div>
              <strong className="text-lg">
                ${(product.price * product.quantity).toFixed(2)}
              </strong>
            </div>
          ))}
        </div>
      </div>
      <div className="fixed bottom-0 w-full bg-white text-black p-4 pb-8 mb-2 border-t-4 border-blue-900 text-center">
        <p>Subtotal: ${subtotal}</p>
        <p>VAT (10%): ${vat}</p>
        <p>Total: ${total}</p>
      </div>
    </div>
  );
};

export default RemoteCart;

// Tailwind CSS configuration in your project is assumed
// Additional custom styles can be added in the global CSS file
/*
body {
    background-color: #EBFFFE;
    font-size: 18px;
}
*/
