import webCurrencyCodes from './webCurrencyCodes';

const getCurrencyByCountryCode = (countryCode) => {
    const countryCurrencyMap = {
        US: webCurrencyCodes.USD,
        GB: webCurrencyCodes.GBP,
        AE: webCurrencyCodes.AED,
        NG: webCurrencyCodes.NGN,
        KE: webCurrencyCodes.KES,
        GH: webCurrencyCodes.GHS,
        ZA: webCurrencyCodes.ZAR,
        EG: webCurrencyCodes.EGP,
        CA: webCurrencyCodes.CAD,
        MX: webCurrencyCodes.MXN,
        CN: webCurrencyCodes.CNY,
        JP: webCurrencyCodes.JPY,
        DE: webCurrencyCodes.EUR,
        FR: webCurrencyCodes.EUR,
        IN: webCurrencyCodes.INR,
        BR: webCurrencyCodes.BRL,
        KR: webCurrencyCodes.KRW,
        IT: webCurrencyCodes.EUR,
        AU: webCurrencyCodes.AUD,
        NL: webCurrencyCodes.EUR,
        HK: webCurrencyCodes.HKD,
        BE: webCurrencyCodes.EUR,
        CH: webCurrencyCodes.CHF,
        ES: webCurrencyCodes.EUR,
        SG: webCurrencyCodes.SGD,
        RU: webCurrencyCodes.RUB,
        MY: webCurrencyCodes.MYR,
        TH: webCurrencyCodes.THB,
        SE: webCurrencyCodes.SEK,
        SA: webCurrencyCodes.SAR,
        AR: webCurrencyCodes.ARS,
        PL: webCurrencyCodes.PLN,
        TR: webCurrencyCodes.TRY,
        PH: webCurrencyCodes.PHP,
        ID: webCurrencyCodes.IDR,
        VN: webCurrencyCodes.VND,
        IE: webCurrencyCodes.EUR,
        IL: webCurrencyCodes.ILS,
        AT: webCurrencyCodes.EUR,
        CL: webCurrencyCodes.CLP,
        NO: webCurrencyCodes.NOK,
        CZ: webCurrencyCodes.CZK,
        HU: webCurrencyCodes.HUF,
        PT: webCurrencyCodes.EUR,
        FI: webCurrencyCodes.EUR,
        NZ: webCurrencyCodes.NZD,
        DK: webCurrencyCodes.DKK,
        CO: webCurrencyCodes.COP,
        PE: webCurrencyCodes.PEN,
        RO: webCurrencyCodes.RON,
        GR: webCurrencyCodes.EUR,
        DZ: webCurrencyCodes.DZD,
        AO: webCurrencyCodes.AOA,
        BJ: webCurrencyCodes.XOF,
        BW: webCurrencyCodes.BWP,
        BF: webCurrencyCodes.XOF,
        BI: webCurrencyCodes.BIF,
        CV: webCurrencyCodes.CVE,
        CM: webCurrencyCodes.XAF,
        CF: webCurrencyCodes.XAF,
        TD: webCurrencyCodes.XAF,
        KM: webCurrencyCodes.KMF,
        CG: webCurrencyCodes.XAF,
        CD: webCurrencyCodes.CDF,
        DJ: webCurrencyCodes.DJF,
        GQ: webCurrencyCodes.XAF,
        ER: webCurrencyCodes.ERN,
        SZ: webCurrencyCodes.SZL,
        ET: webCurrencyCodes.ETB,
        GA: webCurrencyCodes.XAF,
        GM: webCurrencyCodes.GMD,
        GN: webCurrencyCodes.GNF,
        GW: webCurrencyCodes.GWP,
        CI: webCurrencyCodes.XOF,
        LS: webCurrencyCodes.LSL,
        LR: webCurrencyCodes.LRD,
        LY: webCurrencyCodes.LYD,
        MG: webCurrencyCodes.MGA,
        MW: webCurrencyCodes.MWK,
        ML: webCurrencyCodes.XOF,
        MR: webCurrencyCodes.MRO,
        MU: webCurrencyCodes.MUR,
        MA: webCurrencyCodes.MAD,
        MZ: webCurrencyCodes.MZN,
        NA: webCurrencyCodes.NAD,
        NE: webCurrencyCodes.XOF,
        RW: webCurrencyCodes.RWF,
        ST: webCurrencyCodes.STN,
        SN: webCurrencyCodes.XOF,
        SC: webCurrencyCodes.SCR,
        SL: webCurrencyCodes.SLL,
        SO: webCurrencyCodes.SOS,
        SD: webCurrencyCodes.SDG,
        TZ: webCurrencyCodes.TZS,
        TG: webCurrencyCodes.XOF,
        TN: webCurrencyCodes.TND,
        UG: webCurrencyCodes.UGX,
        ZM: webCurrencyCodes.ZMW,
        ZW: webCurrencyCodes.ZWL
    };

    return countryCurrencyMap[countryCode] || webCurrencyCodes.USD; // Default to USD if not found
};

export default getCurrencyByCountryCode;
