import { Listbox, Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import countryJson from "../../utils/country";

export default function PhoneInput({ onChange, countryCode }) {
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    dial_code: "+1",
    code: "US",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
  });

  useEffect(() => {
    setCountryList(JSON.parse(JSON.stringify(countryJson)));
    const setInitialCountry = () => {
      const defaultCountry = countryJson.find(
        (country) => country.code === countryCode
      );
      if (defaultCountry) {
        setSelectedCountry(defaultCountry);
      }
    };

    setInitialCountry();
  }, [countryCode]);

  const onCountrySelect = (e) => {
    setSelectedCountry(JSON.parse(e));
  };

  const onInputChange = (e) => {
    const value = selectedCountry.dial_code + e.target.value;
    // console.log(value);
    onChange(value);
  };

  return (
    <div className="flex gap-4 border p-1 h-10 rounded bg-white">
      <Listbox value={selectedCountry} onChange={onCountrySelect}>
        {({ open }) => (
          <div className="mt-1">
            <Listbox.Button className="flex gap-2  items-center bg-white focus:outline-none pr-2 border-r">
              <img
                src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${selectedCountry.code}.svg`}
                alt=""
                className="w-6"
              />
              <p className="text-sm">{selectedCountry.dial_code}</p>
              {open ? <GoTriangleUp /> : <GoTriangleDown />}
            </Listbox.Button>
            <Transition
              // as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 w-full h-12 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <Scrollbars>
                  {countryList.map((country) => (
                    <Listbox.Option
                      // key={person.id}
                      value={JSON.stringify(country)}
                      // disabled={person.unavailable}
                      // className={"flex gap-2 items-center"}
                      className={({ active }) =>
                        `cursor-default select-none relative py-2 pl-10 pr-4 flex items-center gap-2  ${
                          active
                            ? "text-amber-900 bg-amber-100"
                            : "text-gray-900"
                        }`
                      }
                    >
                      <img
                        src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${country.code}.svg`}
                        alt=""
                        className="w-6"
                      />
                      {country.name}
                      <p className="ml-2 text-sm"> {country.dial_code}</p>
                    </Listbox.Option>
                  ))}
                </Scrollbars>
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
      <input
        type="number"
        onChange={(e) => onInputChange(e)}
        className="flex-1 focus:outline-none text-sm"
      />
    </div>
  );
}
