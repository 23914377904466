import StorageServices from "./StorageServices";
import requests from "./httpService";

const uploadServices = {
  uploadImage(image, entity, entityId) {
    const file = new File([image], image.name);
    const formData = new FormData();
    formData.append("file", file);
    const storeId = StorageServices.get("SB-Active-Store-Id");

    const url = `/marketplace/v1/storage/${storeId}/media/upload?entity=${entity}&entityId=${entityId}`;
    return requests.post(url, formData);
  },

  uploadBanner(image, entity, entityId) {
    const file = image[0];
    const formData = new FormData();
    formData.append("file", file);

    const storeId = StorageServices.get("SB-Active-Store-Id");
    const url = `/marketplace/v1/storage/${storeId}/banners/upload?entity=${entity}&entityId=${entityId}`;
    return requests.post(url, formData);
  },

  async uploadFile(file, entity, entityId) {
    if(!file){
      return null;
    }
    let mediaId = null;
    let uploadStatus = "failed";
    try {
      const response = await this.getFileSignedUrl(file, entity, entityId);
      const data = response ? response.data : null;
      const signedUrl = data ? data.url : null;
      mediaId = data ? data.mediaId : null;
      //
      await this.uploadToS3(file, signedUrl);
      uploadStatus = "success"; // Update status to active on successful upload
    } catch (uploadError) {
      console.error("Error during image upload:", uploadError);
      if (uploadError.response && uploadError.response.status === 412) {
        const errorMessage = uploadError.response.data.message;
        throw new Error(errorMessage);
      } else {
        throw new Error(
          "File upload failed. Please try again or contact support."
        );
      }
    }
    try {
      // Update file status based on the outcome of the upload, if mediaId is available
      if (mediaId !== null) {
        return this.updateMediaStatus(mediaId, uploadStatus);
      }
    } catch (statusUpdateError) {
      console.error("Error during status update:", statusUpdateError);
    }
    return null;
  },

  async uploadPublicFile(file, entity, entityId) {
    if(!file){
      return null;
    }
    let mediaId = null;
    let uploadStatus = "failed";
    try {
      const response = await this.getFileSignedUrlForPublicFile(file, entity, entityId);
      const data = response ? response.data : null;
      const signedUrl = data ? data.url : null;
      mediaId = data ? data.mediaId : null;
      //
      await this.uploadToS3(file, signedUrl);
      uploadStatus = "success"; // Update status to active on successful upload
    } catch (uploadError) {
      console.error("Error during image upload:", uploadError);
      if (uploadError.response && uploadError.response.status === 412) {
        const errorMessage = uploadError.response.data.message;
        throw new Error(errorMessage);
      } else {
        throw new Error(
          "File upload failed. Please try again or contact support."
        );
      }
    }
    try {
      // Update file status based on the outcome of the upload, if mediaId is available
      if (mediaId !== null) {
        return this.updateMediaStatus(mediaId, uploadStatus);
      }
    } catch (statusUpdateError) {
      console.error("Error during status update:", statusUpdateError);
    }
    return null;
  },

  getFileSignedUrl(file, entity, entityId) {
    if(!file){
      return null;
    }
    const storeId = StorageServices.get("SB-Active-Store-Id");
    //
    const filename = encodeURIComponent(file.name);
    const contentType = encodeURIComponent(file.type);
    const extension = encodeURIComponent(file.name.split(".").pop());
    const size = file.size; // Size in bytes
    const duration = file.duration
      ? `&duration=${encodeURIComponent(file.duration)}`
      : ""; // Duration in seconds
    const height = file.height
      ? `&height=${encodeURIComponent(file.height)}`
      : "";
    const width = file.width ? `&width=${encodeURIComponent(file.width)}` : "";
    //
    const url = `/marketplace/v1/storage/${storeId}/files/get-upload-signed-url?entity=${entity}&entityId=${entityId}&filename=${filename}&contentType=${contentType}&ext=${extension}&size=${size}${duration}${height}${width}`;
    //
    return requests.get(url);
  },

  // This will get a private signed url to upload 
  // files that are meant to be publicly available 
  // mainly product demo vidoes.
  getFileSignedUrlForPublicFile(file, entity, entityId) {
    if(!file){
      return null;
    }
    const storeId = StorageServices.get("SB-Active-Store-Id");
    //
    const filename = encodeURIComponent(file.name);
    const contentType = encodeURIComponent(file.type);
    const extension = encodeURIComponent(file.name.split(".").pop());
    const size = file.size; // Size in bytes
    const duration = file.duration
      ? `&duration=${encodeURIComponent(file.duration)}`
      : 0; // Duration in seconds
    const height = file.height
      ? `&height=${encodeURIComponent(file.height)}`
      : "";
    const width = file.width ? `&width=${encodeURIComponent(file.width)}` : "";
    //
    const url = `/marketplace/v1/storage/${storeId}/files/public/get-upload-signed-url?entity=${entity}&entityId=${entityId}&filename=${filename}&contentType=${contentType}&ext=${extension}&size=${size}${duration}${height}${width}`;
    //
    return requests.get(url);
  },

  uploadToS3(file, signedUrl) {
    if(!file){
      return null;
    }
    if (!signedUrl) {
      return null;
    }
    const headers = new Headers();
    headers.append("Content-Type", file.type);
    const requestOptions = {
      method: "PUT",
      headers: headers,
      body: file,
    };
    return fetch(signedUrl, requestOptions);
  },

  updateMediaStatus(mediaId, status = "success") {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    const url = `/marketplace/v1/storage/${storeId}/media/update-upload-status?mediaId=${mediaId}&status=${status}`;
    //
    return requests.put(url);
  },

  getMediaLibrarySignedUrl(file, tags) {
    if(!file){
      return null;
    }
    const storeId = StorageServices.get("SB-Active-Store-Id");
    //
    const filename = encodeURIComponent(file.name);
    const contentType = encodeURIComponent(file.type);
    const extension = encodeURIComponent(file.name.split(".").pop());
    const size = file.size; // Size in bytes
    const height = file.height
      ? `&height=${encodeURIComponent(file.height)}`
      : "";
    const width = file.width ? `&width=${encodeURIComponent(file.width)}` : "";
    //
    const url = `/marketplace/v1/storage/${storeId}/media/library/get-upload-signed-url?filename=${filename}&tags=${tags}&contentType=${contentType}&ext=${extension}&size=${size}${height}${width}`;
    //
    return requests.get(url);
  },

  async uploadLibraryImage(image, tags) {
    let mediaId = null;
    let uploadStatus = "failed";
    try {
      const response = await this.getMediaLibrarySignedUrl(image, tags);
      const data = response ? response.data : null;
      const signedUrl = data ? data.url : null;
      mediaId = data ? data.mediaId : null;
      //
      await this.uploadToS3(image, signedUrl);
      uploadStatus = "success"; // Update status to active on successful upload
    } catch (uploadError) {
      console.error("Error during image upload:", uploadError);
      if (uploadError.response && uploadError.response.status === 412) {
        const errorMessage = uploadError.response.data.message;
        throw new Error(errorMessage);
      } else {
        throw new Error(
          "Image upload failed. Please try again or contact support."
        );
      }
    }
    try {
      // Update file status based on the outcome of the upload, if mediaId is available
      if (mediaId !== null) {
        return this.updateMediaStatus(mediaId, uploadStatus);
      }
    } catch (statusUpdateError) {
      console.error("Error during status update:", statusUpdateError);
    }
    return null;
  },

  attachMedia(payload) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    const url = `/marketplace/v1/storage/${storeId}/media/attach`;
    return requests.put(url, payload);
  },

  detachhMedia(payload) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    const url = `/marketplace/v1/storage/${storeId}/media/delete`;
    return requests.delete(url, {
      data: payload,
    });
  },

  deleteMediaByIdOrKey(idOrKey) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    const encodedIdOrKey = encodeURIComponent(idOrKey);
    const url = `/marketplace/v1/storage/${storeId}/media/delete/${encodedIdOrKey}`;
    return requests.delete(url, {});
  },

  // entity/media/clean
  // CAUTION: This will remove every media attached to the entity
  // Should be used for entities that take only 1 media normally.
  // This helps to eliminate the occurenece of duplicates due to some delete
  // requests failing and entities that are supposed to have just one media,
  // now having multiple.
  cleanMediaForEntity(entityId, entityType) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    // URL encode the entityType
    const encodedEntityType = encodeURIComponent(entityType);
    const url = `/marketplace/v1/storage/${storeId}/entity/media/clean?entityId=${entityId}&entity=${encodedEntityType}`;
    return requests.delete(url, {});
  },

  getImages(ids, entity) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    const url = `/marketplace/v1/public/${storeId}/media/fetch?entity=${entity}&ids=${ids}&size=medium&all=true`;
    return requests.get(url);
  },

  getImage(id, entity) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    const url = `/marketplace/v1/public/${storeId}/media/fetch/${id}?entity=${entity}&size=medium&all=true`;
    return requests.get(url);
  },

  getImageWithoutEntity(id) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    const url = `/marketplace/v1/storage/${storeId}/media/fetch?ids=${id}`;
    return requests.get(url);
  },
};

export default uploadServices;
