import { useState, useEffect } from "react";

const useIdentity = () => {
  const [gettingIdentity, setGettingIdentity] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    const fetchIdentity = async () => {
      setGettingIdentity(true);
      try {
        const response = await fetch("https://sb-apis.ibunchng.com/marketplace/v1/public/identity");
        const data = await response.json();
        // console.log(" ---- Identity:   ", data);
        setIpAddress(data.ip_address);
        setCountryCode(data.country_code);
      } catch (error) {
        console.error("Error fetching identity:", error);
      } finally {
        setGettingIdentity(false);
      }
    };

    fetchIdentity();
  }, []);

  return { gettingIdentity, ipAddress, countryCode };
};

export default useIdentity;
