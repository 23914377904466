import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { SidebarContext } from "../context/SidebarContext";
import StorageServices from "../services/StorageServices";

function AccessibleNavigationAnnouncer() {
  const [message, setMessage] = useState("");
  const location = useLocation();

  const { toggleTrue, toggleFalse, toggleAutoClose, setCollapsed } =
    useContext(SidebarContext);

  useEffect(() => {
    // ignore the /
    if (location.pathname.slice(1)) {
      // make sure navigation has occurred and screen reader is ready
      setTimeout(
        () => setMessage(`Navigated to ${location.pathname.slice(1)} page.`),
        500
      );
    } else {
      setMessage("");
    }
  }, [location]);

  useEffect(() => {
    const autoCollapsePages = ["/pos/", "/edit-store-front"];
    const shouldAutoCollapse = autoCollapsePages.some((snippet) =>
      location.pathname.includes(snippet)
    );
    if (shouldAutoCollapse) {
      setCollapsed(false);
      toggleAutoClose(true);
    } else {
      const autocloseStatus = StorageServices.get(
        "SB-Sidebar-Collapse-Autoclose"
      )
        ? JSON.parse(StorageServices.get("SB-Sidebar-Collapse-Autoclose"))
        : false;
      if (autocloseStatus) {
        setCollapsed(true);
        toggleTrue();
        toggleAutoClose(false);
      }
    }
  }, [location]);

  return (
    <span
      className="sr-only"
      role="status"
      aria-live="polite"
      aria-atomic="true"
    >
      {message}
    </span>
  );
}

export default AccessibleNavigationAnnouncer;
