import { Label } from "@windmill/react-ui";
import React from "react";

const LabelArea = ({ label, className, optional = false, ...props }) => {
  return (
    <Label
      className={` col-span-4 sm:col-span-2 text-sm whitespace-no-wrap text-gray-500 dark:text-gray-300 ${className}`}
      {...props}
      // style={{ color: "#696F79" }}
    >
      {label}
      {optional && <span className="text-xs font-light px-1">
        (Optional)
        </span>}
    </Label>
  );
};

export default LabelArea;
