import Cookies from "js-cookie";
import StorageServices from "./StorageServices";
import requests, { uninterceptedAxiosInstance } from "./httpService";
const StoreServices = {
  async start() {
    const token = Cookies.get("SB-user-token");
    const { data } = await uninterceptedAxiosInstance.get(
      "/marketplace/v1/user/start",
      {
        headers: {
          Authorization: token,
          clientId: "storebounty", //`${process.env.APP_ID}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  },
  getStores() {
    return requests.get("/marketplace/v1/stores/all");
  },
  createStore(body) {
    return requests.post("/marketplace/v1/stores/create", body);
  },
  createStoreBranch(body) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.post(`/marketplace/v1/stores/${storeId}/branches/create`, body);
  },
  getActiveStoreBranchesBasic(addBusinessToken = false) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.get(`/marketplace/v1/stores/${storeId}/branches/basic?addToken=${addBusinessToken}`);
  },
  getActiveStoreBranches() {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.get(`/marketplace/v1/stores/${storeId}/branches`);
  },
  getStoreInfo(id) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.get(`/marketplace/v1/stores/${id || storeId}`);
  },
  updateStoreInfo(data) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.put(`/marketplace/v1/stores/${storeId}/update`, data);
  },
  updateSocialInfo(data) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.put(
      `/marketplace/v1/stores/${storeId}/update/social`,
      data
    );
  },
  updateVat(data) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.put(`/marketplace/v1/stores/${storeId}/update/vat`, data);
  },
  uploadLogo(image) {
    const file = new FormData();
    file.append("file", image);
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.post(
      `/marketplace/v1/images/${storeId}/upload?entity=store`,
      file
    );
  },

  logoUpdate(data) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    const payload = { ...data, store_id: storeId };
    return requests.post(`/marketplace/v1/stores/${storeId}/logo`, payload);
  },

  logoDelete(payload) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.delete(`/marketplace/v1/stores/${storeId}/logo`, payload);
  },

  getInvite() {
    // const storeId = StorageServices.get("SB-Active-Store-Id");

    // return requests.get(`/accounts/api/v1/business/${storeId}/invites/inbox`);
    return requests.get(`/accounts/api/v1/users/invites/inbox`);
  },

  acceptRejectInvite(payload) {
    return requests.post("/accounts/api/v1/users/invites/update", payload);
  },

  goLive(payload) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.put(`/marketplace/v1/stores/${storeId}/mode/live`, payload);
  },

  tryEnterprice(payload) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.put(
      `/marketplace/v1/stores/${storeId}/trial/start`,
      payload
    );
  },

  deleteAccount(payload) {
    return requests.delete(`accounts/api/v1/users/account/delete`, payload);
  },
};

export default StoreServices;
