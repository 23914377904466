const webCurrencyCodes = {
    USD: "USD", // United States Dollar
    GBP: "GBP", // British Pound Sterling
    AED: "AED", // United Arab Emirates Dirham
    NGN: "NGN", // Nigerian Naira
    KES: "KES", // Kenyan Shilling
    GHS: "GHS", // Ghanaian Cedi
    ZAR: "ZAR", // South African Rand
    EGP: "EGP", // Egyptian Pound
    CAD: "CAD", // Canadian Dollar
    MXN: "MXN", // Mexican Peso
    CNY: "CNY", // Chinese Yuan
    JPY: "JPY", // Japanese Yen
    EUR: "EUR", // Euro (Germany, France, Italy, Spain, Netherlands, etc.)
    INR: "INR", // Indian Rupee
    BRL: "BRL", // Brazilian Real
    KRW: "KRW", // South Korean Won
    AUD: "AUD", // Australian Dollar
    HKD: "HKD", // Hong Kong Dollar
    CHF: "CHF", // Swiss Franc
    SGD: "SGD", // Singapore Dollar
    RUB: "RUB", // Russian Ruble
    MYR: "MYR", // Malaysian Ringgit
    THB: "THB", // Thai Baht
    SEK: "SEK", // Swedish Krona
    SAR: "SAR", // Saudi Riyal
    ARS: "ARS", // Argentine Peso
    PLN: "PLN", // Polish Zloty
    TRY: "TRY", // Turkish Lira
    PHP: "PHP", // Philippine Peso
    IDR: "IDR", // Indonesian Rupiah
    VND: "VND", // Vietnamese Dong
    ILS: "ILS", // Israeli Shekel
    CLP: "CLP", // Chilean Peso
    NOK: "NOK", // Norwegian Krone
    CZK: "CZK", // Czech Koruna
    HUF: "HUF", // Hungarian Forint
    DKK: "DKK", // Danish Krone
    COP: "COP", // Colombian Peso
    PEN: "PEN", // Peruvian Sol
    RON: "RON", // Romanian Leu
    DZD: "DZD", // Algerian Dinar
    AOA: "AOA", // Angolan Kwanza
    XOF: "XOF", // West African CFA Franc (Benin, Burkina Faso, Ivory Coast, Mali, Niger, Senegal, Togo)
    BWP: "BWP", // Botswana Pula
    BIF: "BIF", // Burundian Franc
    CVE: "CVE", // Cape Verdean Escudo
    XAF: "XAF", // Central African CFA Franc (Cameroon, Central African Republic, Chad, Congo, Equatorial Guinea, Gabon)
    KMF: "KMF", // Comorian Franc
    CDF: "CDF", // Congolese Franc
    DJF: "DJF", // Djiboutian Franc
    ERN: "ERN", // Eritrean Nakfa
    SZL: "SZL", // Swazi Lilangeni
    ETB: "ETB", // Ethiopian Birr
    GMD: "GMD", // Gambian Dalasi
    GNF: "GNF", // Guinean Franc
    GWP: "GWP", // Guinea-Bissau Peso
    LSL: "LSL", // Lesotho Loti
    LRD: "LRD", // Liberian Dollar
    LYD: "LYD", // Libyan Dinar
    MGA: "MGA", // Malagasy Ariary
    MWK: "MWK", // Malawian Kwacha
    MRO: "MRO", // Mauritanian Ouguiya
    MUR: "MUR", // Mauritian Rupee
    MAD: "MAD", // Moroccan Dirham
    MZN: "MZN", // Mozambican Metical
    NAD: "NAD", // Namibian Dollar
    RWF: "RWF", // Rwandan Franc
    STN: "STN", // São Tomé and Príncipe Dobra
    SCR: "SCR", // Seychellois Rupee
    SLL: "SLL", // Sierra Leonean Leone
    SOS: "SOS", // Somali Shilling
    SDG: "SDG", // Sudanese Pound
    TZS: "TZS", // Tanzanian Shilling
    UGX: "UGX", // Ugandan Shilling
    ZMW: "ZMW", // Zambian Kwacha
    ZWL: "ZWL"  // Zimbabwean Dollar
  };
  
  export default webCurrencyCodes;
  