import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomToastMessage = ({ message }) => (
  <div className="bg-white rounded-lg p-3 max-w-sm mx-auto">
    <h3 className="text-lg font-semibold text-gray-900">{message?.title}</h3>
    {message?.isHtml ? (
      <div
        className="text-gray-700 mt-1"
        dangerouslySetInnerHTML={{ __html: message?.body }}
      />
    ) : (
      <p className="text-gray-700 mt-1">{message?.body}</p>
    )}
    {message?.link && (
      <button
        onClick={() => window.location.href = message?.link?.url}
        className="mt-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        {message?.link?.text}
      </button>
    )}
  </div>
);


const defaultOptions = {
  position: "top-center", // Set the default position as a string
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

// Maps string positions to toastify positions
const positionMap = {
  "top-right": toast.POSITION.TOP_RIGHT,
  "top-center": toast.POSITION.TOP_CENTER,
  "top-left": toast.POSITION.TOP_LEFT,
  "bottom-right": toast.POSITION.BOTTOM_RIGHT,
  "bottom-center": toast.POSITION.BOTTOM_CENTER,
  "bottom-left": toast.POSITION.BOTTOM_LEFT,
};

const notify = (type, message, options = {}) => {
  const { sticky = false, position } = options;
  const autoClose = sticky ? false : defaultOptions.autoClose;

  toast[type](message, {
    ...defaultOptions,
    ...options,
    autoClose,
    position: positionMap[position] || positionMap[defaultOptions.position], // Map the position string to toast.POSITION
  });
};

const notifySuccess = (message, options) => notify('success', message, options);
const notifyError = (message, options) => notify('error', message, options);
const notifyInfo = (message, options) => notify('info', message, options);
const notifyWarning = (message, options) => notify('warning', message, options);
// 
const notifyCustom = (messageObject, options = {}) => {
  const { type = 'info', sticky = true, position } = options; // Default type to 'info'
  toast[type](<CustomToastMessage message={messageObject} />, {
    ...defaultOptions,
    ...options,
    autoClose: sticky ? false : defaultOptions.autoClose,
    position: positionMap[position] || positionMap[defaultOptions.position], // Use provided or default position
  });
};

<ToastContainer
  position="top-center"
  autoClose={3000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
/>;

export { ToastContainer, notifySuccess, notifyInfo, notifyError, notifyWarning, notifyCustom };
