import React from "react";
import logoBlue from "../../assets/img/Storebounty_icon.png";
import logoWhite from "../../assets/img/Storebounty_icon_white.png";
import useDarkMode from "../../hooks/useDarkMode";

export default function Logo({
  className,
  margin = "  hidden md:block lg:ml-24 mt-8 mb-6",
}) {
  const mode = useDarkMode();
let logo = logoBlue;
  if(mode === 'dark'){
    logo = logoWhite;
  }
  console.log('----- Mode:    ',)
  return (
    <a
      href={process.env.REACT_APP_SB_HOME_URL}
      className={`self-start justify-self-start logo ${margin}`}
    >
      <img src={logo} alt="" className={className} />
    </a>
  );
}
