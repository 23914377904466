import Cookies from "js-cookie";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AdminContext } from "../context/AdminContext";
import StorageServices from "../services/StorageServices";
import StoreServices from "../services/StoreServices";

export default function useSelectStore() {
  const history = useHistory();

  const { dispatch } = useContext(AdminContext);

  const handleStoreSelect = async (storeId, user) => {
    // Reset active user info
    dispatch({ type: "USER_LOGIN", payload: user?.info });
    Cookies.set("SB-user-token", "Bearer " + user?.token);
    StorageServices.set("adminInfo", JSON.stringify(user?.info));

    // fetch new store
    const stores = await StoreServices.start();

    //select new store
    const activeStore = stores?.data?.stores?.find(
      (store) => store.id == storeId
    );

    if (activeStore) {
      // Set store info
      StorageServices.set("SB-Active-Store-Id", activeStore.id);
      StorageServices.set(
        "SB-Active-Store-Currency-Code",
        activeStore.currency_code
      );
      StorageServices.set(
        "SB-Active-Store-Stats",
        JSON.stringify(activeStore.stats)
      );
      StorageServices.set("SB-Active-Store", JSON.stringify(activeStore));
      // StorageServices.set("SB-Stores", JSON.stringify(data.stores));
      Cookies.remove("SB-business-token");
      Cookies.set("SB-business-token", "Bearer " + activeStore.business_token);
      if (!activeStore.default_theme) {
        history.replace("/dashboard/theme");
        return;
      }
      if (activeStore?.stats?.product_count < 1) {
        history.replace("/products");
        return;
      }

      dispatch({ type: "ACTIVE_STORE", payload: activeStore });

      //const userRole = user?.roles?.[activeStore.id]?.[0];

      // if (userRole === "admin" || userRole === "super_admin") {
      //   history.replace("/");
      // } else {
      //   history.replace("/pos/retail");
      // }
      
      history.replace("/");
    }
  };
  return { handleStoreSelect };
}
