import requests from "./httpService";
import StorageServices from "./StorageServices";

const ModuleServices = {
  async getModules() {
    const storeId = StorageServices.get("SB-Active-Store-Id") || 0;
    const cachedModules = StorageServices.get(`modules-${storeId}`);

    if (cachedModules) {
      // Return the cached data, converting back to the expected data type if necessary
      return JSON.parse(cachedModules);
    } else {
      // Fetch from the API and save to local storage
      const response = await requests.get(`/marketplace/v1/stores/${storeId}/modules`);
      StorageServices.set(`modules-${storeId}`, JSON.stringify(response));
      return response;
    }
  },
  async updateModules(payload) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    // Update modules and clear the cache
    const response = await requests.put(`/marketplace/v1/stores/${storeId}/modules`, payload);
    StorageServices.remove(`modules-${storeId}`);
    return response;
  },
};

export default ModuleServices;
