import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import AdminServices from "../../services/AdminServices";
import StorageServices from "../../services/StorageServices";
import { getIP } from "../../utils/getIP";
import { notifyError, notifySuccess } from "../../utils/toast";

const useLoginSubmit = (setActiveTab) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AdminContext);
  const history = useHistory();
  const [otp, setOtp] = useState(null);
  const [defaultPassword, setDefaultPassword] = useState(null);
  const [apiCallSucceeded, setApiCallSucceeded] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const { password, email } = getValues();
  const remember_me = StorageServices.get("SB-remember-me");
  const activeEmail = StorageServices.get("logged-in-email");

  useEffect(() => {
    if (remember_me && remember_me !== "null") {
      activeEmail && setValue("email", activeEmail);
      remember_me && setValue("remember_me", remember_me);
    }
    //eslint-disable-next-line
  }, []);

  const getIPAddress = async () => {
    try {
      return await getIP();
    } catch (err) {
      console.log(err);
    }
  };

  const onLoginSubmit = async (data, e) => {
    e.preventDefault();
    const { email, password, remember_me } = data;

    if (!email || !password) {
      notifyError("Please fill all fields");
      return;
    }

    setLoading(true);
    try {
      const IP = await getIPAddress();
      const res = await AdminServices.loginAdmin({ email, password }, IP);

      setApiCallSucceeded(true);

      if (res.token) {
        const user = res.data?.info;
        const userToken = res?.token;
        notifySuccess("Welcome back, " + user?.first_name + "!");

        if (remember_me) {
          StorageServices.set("SB-remember-me", remember_me);
        } else {
          StorageServices.remove("SB-remember-me");
        }
        StorageServices.set("logged-in-email", email);
        Cookies.set("SB-user-token", "Bearer " + userToken);
        StorageServices.set("adminInfo", JSON.stringify(user));
        if (res.data.invites?.length === 0) {
          dispatch({ type: "USER_LOGIN", payload: user });
        }
        if (user.email_verified) {
          history.push({
            pathname: "/select-store",
            state: {
              invites: res.data.invites,
            },
          });
        } else {
          history.push({
            pathname: "/verify",
            state: {
              invites: res.data.invites,
            },
          });
        }
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setErrorMsg(message);
      setApiCallSucceeded(false);
    } finally {
      setLoading(false);
    }
  };

  const onSignUp = async (data, e) => {
    // e.preventDefault();
    const { email, password, first_name, last_name, phone, referral_code } =
      data;

    setLoading(true);
    try {
      const IP = await getIPAddress();
      const res = await AdminServices.registerAdmin(
        {
          email,
          password,
          first_name,
          last_name,
          phone,
          referral_code,
        },
        IP
      );
      setApiCallSucceeded(true);
      const user = res.data.info;
      const userToken = res.token;
      notifySuccess(res.data.message);
      setSuccessMsg(res.data.message);
      dispatch({ type: "USER_LOGIN", payload: user });
      Cookies.set("SB-user-token", "Bearer " + userToken);
      StorageServices.set("adminInfo", JSON.stringify(user));
      StorageServices.set("logged-in-email", email);
      history.push("/verify");
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setErrorMsg(message);
      setLoading(false);
      setApiCallSucceeded(false);
    }
  };

  const onVerifyEmail = async (data, e) => {
    e.preventDefault();
    console.log("called");
    const { otp } = data;

    if (!otp || otp.length < 4) {
      notifyError("Please enter a valid token");
      return;
    }
    setLoading(true);

    try {
      const res = await AdminServices.verifyEmail({
        otp,
      });
      setApiCallSucceeded(true);
      if (res.data.token) {
        const admin = StorageServices.get("adminInfo")
          ? JSON.parse(StorageServices.get("adminInfo"))
          : {};
        Object.assign(admin, {
          email_verified: true,
        });
        dispatch({ type: "VERIFY_EMAIL" });

        StorageServices.set("adminInfo", JSON.stringify(admin));
        Cookies.set("SB-user-token", "Bearer " + res?.data?.token);
        notifySuccess(res.data?.message);
        setSuccessMsg(res.data?.message);
        history.push("/select-store"); // select store will push you to onboarding if you got no store
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setErrorMsg(message);
      setLoading(false);
      setApiCallSucceeded(false);
    }
  };

  const onsendResetOtp = async (data, e) => {
    if (!data.email) {
      return;
    }
    const payload = {
      app_id: "storebounty",
      email: data.email,
    };
    setLoading(true);
    try {
      const otp = await AdminServices.sendRestOTP(payload);
      setApiCallSucceeded(true);
      StorageServices.set("email", data.email);
      setActiveTab(1);
      notifySuccess(otp.message);
      setSuccessMsg(otp?.message);
      // console.log(res);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setErrorMsg(message);
      setApiCallSucceeded(false);
    } finally {
      setLoading(false);
    }
  };

  const onResetPassword = async (data, e) => {
    if (e) {
      e.preventDefault();
    }
    const payload = {
      app_id: "storebounty",
      otp: data.otp ? data.otp : otp,
      email: data.email || email,
      new_password: data.new_password,
      repeat_password: data.repeat_password,
    };
    setLoading(true);
    try {
      const res = await AdminServices.forgetPassword(payload);
      setApiCallSucceeded(true);

      // this onResetPassword func is used in multiple places, just making sure the tab switchs only on forget screen

      setActiveTab((prev) => prev + 1);

      notifySuccess(res.data.message);
      setSuccessMsg(res.data?.message);
      StorageServices.remove("email");
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setErrorMsg(message);
      setApiCallSucceeded(false);
    } finally {
      setLoading(false);
    }
  };

  const onsendEmailOtp = async (data, e) => {
    if (!data.email) {
      return;
    }
    const payload = {
      app_id: "storebounty",
      email: data.email,
    };
    setLoading(true);
    try {
      const otp = await AdminServices.sendEmailOTP(payload);
      setApiCallSucceeded(true);
      StorageServices.set("email", data.email);
      notifySuccess(otp.message);
      setSuccessMsg(otp?.message);
      // console.log(res);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setErrorMsg(message);
      setApiCallSucceeded(false);
    } finally {
      setLoading(false);
    }
  };

  const onValidateInvite = async (data, e) => {
    if (!data.token || !data.invite_id) {
      notifyError("Invalid link. Please contact your store administrator.");
      return;
    }
    setLoading(true);
    try {
      const res = await AdminServices.registerByInvite(data);
      setApiCallSucceeded(true);
      // console.log(res);
      // 
      if (res) {
        notifySuccess(res.data?.message);
        setSuccessMsg(res.data?.message);
        //
        const user = res.data?.info;
        StorageServices.set("SB-remember-me", true);
        StorageServices.set("logged-in-email", user.email);
        const isNewAcct = res?.data?.is_new_acct;
        if (isNewAcct == true) {
          setDefaultPassword(res?.data?.default_password);
          setOtp(res?.data?.password_otp);
          setActiveTab(1);
          // 
        } else {
          const userToken = res?.token;
          Cookies.set("SB-user-token", "Bearer " + userToken);
          StorageServices.set("adminInfo", JSON.stringify(user));
          dispatch({ type: "USER_LOGIN", payload: user });
          history.push({
            pathname: "/select-store",
            state: {
              invites: res.data.invites,
            },
          });
        }
      }

    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setErrorMsg(message);
      setApiCallSucceeded(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    onLoginSubmit,
    onSignUp,
    onVerifyEmail,
    register,
    handleSubmit,
    errors,
    loading,
    password,
    Controller,
    control,
    reset,
    onsendResetOtp,
    onResetPassword,
    email,
    onsendEmailOtp,
    onValidateInvite,
    apiCallSucceeded,
    successMsg,
    errorMsg,
    otp,
    defaultPassword
  };
};

export default useLoginSubmit;
