import { Button, HelperText, Label } from "@windmill/react-ui";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ClipLoader from "react-spinners/ClipLoader";
import Error from "../../components/form/Error";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import LabelArea from "../../components/form/LabelArea";
import PhoneInput from "../../components/form/PhoneInput";
import Logo from "../../components/logo/Logo";
import useIosAuth from "../../hooks/formSubmits/useIosAuth";
import useIdentity from "../../hooks/useIdentity";

const IosSignUp = () => {
  const [showPassword, setShowPassword] = useState(false);

  const { gettingIdentity, countryCode } = useIdentity();

  const {
    onSignUp,
    register,
    handleSubmit,
    errors,
    loading,
    control,
    Controller,
  } = useIosAuth();

  return (
    <div className="w-full min-h-screen bg-gray-50 pb-32">
      <div className="flex flex-col items-center justify-center max-w-7xl mx-auto">
        <Logo />
        <main className="flex items-center justify-center p-6  mx-auto max-w-2xl w-full">
          <div className="w-full">
            <h1 className="text-xl font-semibold text-gray-700   text-center pb-6">
              Register
            </h1>
            <p className="text-sm border-b mb-6 pb-6 text-center text-gray-600">
              Signup to{" "}
              <strong className="text-green-500 pr-1">Store Bounty</strong>
              and get the best out of our platform for free.
            </p>
            <form
              onSubmit={(e) => handleSubmit(onSignUp)(e)}
              className="md:grid grid-cols-2 gap-4 space-y-2 md:space-y-0"
            >
              <div className="flex flex-col gap-1">
                <LabelArea label="First Name*" />
                <input
                  {...register("first_name", { required: true })}
                  required
                  // defaultValue={loggedInEmail}
                  className="focus:border-green-500 focus:shadow-md border px-3 h-10 rounded  text-sm focus:outline-none block w-full  focus:bg-white"
                  label="first_name"
                  name="first_name"
                  type="text"
                  placeholder=""
                />
                <HelperText valid={false}>
                  {errors.first_name?.type === "required" &&
                    "First name is required"}
                </HelperText>
              </div>
              <div className=" flex flex-col gap-1">
                <LabelArea label="Last Name*" />
                <input
                  {...register("last_name", { required: true })}
                  className="focus:border-green-500 focus:shadow-md border px-3 h-10 rounded  text-sm focus:outline-none block w-full  focus:bg-white"
                  // defaultValue=""
                  label="last_name"
                  name="last_name"
                  type="text"
                  placeholder=""
                />
                <HelperText valid={false}>
                  {errors.last_name?.type === "required" &&
                    "Last name is required"}
                </HelperText>
              </div>
              <div className="flex flex-col gap-1">
                <LabelArea label="Email address*" />
                <input
                  {...register("email", { required: true })}
                  required
                  // defaultValue={loggedInEmail}
                  className="focus:border-green-500 focus:shadow-md border px-3 h-10 rounded  text-sm focus:outline-none block w-full  focus:bg-white "
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter email address"
                />
                <HelperText valid={false}>
                  {errors.email?.type === "required" && "Email is required"}
                </HelperText>
              </div>
              {!gettingIdentity && (
                <div className="flex flex-col gap-1 relative">
                  <LabelArea label="Enter phone number" />
                  <Controller
                    control={control}
                    name="phone"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <PhoneInput
                        onChange={onChange}
                        countryCode={countryCode}
                      />
                    )}
                  />

                  <Error errorName={errors.phone} />
                </div>
              )}
              <div className="flex flex-col gap-1 relative">
                <LabelArea label="Enter password*" />
                <input
                  {...register("password", { minLength: 4, required: true })}
                  required
                  className="focus:border-green-500 focus:shadow-md border px-3 h-10 rounded  text-sm focus:outline-none block w-full  focus:bg-white"
                  label="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder=""
                />
                <HelperText valid={false}>
                  {errors.password?.type === "required" &&
                    "Password is required"}
                  {errors.password?.type === "minLength" &&
                    "Password must be at least 4 characters"}
                </HelperText>

                <label className={`absolute right-0 mr-3 mt-8`}>
                  {
                    <>
                      {showPassword ? (
                        <AiOutlineEye className="text-green-600 mt-1" />
                      ) : (
                        <AiOutlineEyeInvisible className="text-gray-400 mt-1" />
                      )}
                    </>
                  }

                  <input
                    className="appearance-none"
                    type="checkbox"
                    checkbox={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                </label>
              </div>
              <div className="flex flex-col gap-1 relative">
                <LabelArea label="Enter referral code (optional)" />
                <input
                  {...register("referral_code", {
                    minLength: 4,
                  })}
                  className="focus:border-green-500 focus:shadow-md border px-3 h-10 rounded  text-sm focus:outline-none block w-full  focus:bg-white"
                  label="Referral Code"
                  name="referral_code"
                  type={"text"}
                  placeholder=""
                />
                <HelperText valid={false}>
                  {errors.referral_code?.type === "required" &&
                    "Referral Code is required"}
                </HelperText>
              </div>

              <Label className="mt-2 xl col-span-2 " check>
                <input
                  register={register}
                  required
                  name="policy"
                  type="checkbox"
                  className="w-5 h-5"
                />
                <span className="ml-2 text-sm">
                  By clicking this, you agree to Store Bounty{" "}
                  <a
                    href={`${process.env.REACT_APP_SB_HOME_URL}/pages/terms-and-conditions`}
                    className="text-green-500"
                  >
                    Terms & Conditons{" "}
                  </a>
                  {" and"}
                  <a
                    href={`${process.env.REACT_APP_SB_HOME_URL}/pages/privacy-policy`}
                    className="text-green-500 ml-1"
                  >
                    Privacy Policy
                  </a>
                </span>
              </Label>
              <hr className="mb-4 mt-2 col-span-2" />
              <div className="col-span-2 flex justify-center">
                <Button
                  disabled={gettingIdentity || loading}
                  type="submit"
                  className="mx-auto w-full lg:w-1/2 flex gap-2"
                >
                  {loading && <ClipLoader size={20} color="white" />} Create an Account
                </Button>
              </div>
            </form>
            <p className="mt-3 flex justify-center items-center gap-1 text-sm mx-autodark:text-white">
              Already have an account?
              <Link
                className="text-sm font-bold text-green-500 dark:text-green-400 hover:underline"
                to="/login/ios"
              >
                Login
              </Link>
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default IosSignUp;
