import { FaInfoCircle } from "react-icons/fa";

export default function Hints({
  hint,
  type = "grey",
  bold = false,
  icon = false,
  iconSize = "xs",
}) {
  // Define the classes for different hint types
  const typeClasses = {
    grey: "text-gray-700",
    info: "text-green-900",
    warning: "text-yellow-900",
    danger: "text-red-900",
    success: "text-blue-900",
  };

  // Define the classes for different icon sizes
  const sizeClasses = {
    xs: "h-4 w-4",
    sm: "h-5 w-5",
    md: "h-6 w-6",
    lg: "h-8 w-8",
  };

  return (
    <div className="text-xs flex gap-1 lg:items-center">
      {icon && (
        <FaInfoCircle
          className={`${sizeClasses[iconSize]} ${typeClasses[type]} dark:text-white pr-1`}
        />
      )}
      <span
        className={`${typeClasses[type]} dark:text-gray-400 ${
          bold ? "font-medium" : "font-light"
        }`}
      >
        {hint}
      </span>
    </div>
  );
}
